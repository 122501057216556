"use strict";
// This file is a prime candidate for refactoring. Some suggested helper functions to create and use:
// - getFoundationTop(game: IGame): IFoundationTop
// - getFoundationTopCards(game: IGame): ICard[]
// - getPlayablePiles(game: IGame): IPile[] // only piles where each pile contains at least one revealed card
// - ...and others
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMoveHints = exports.MOVE_CONFIDENCES = exports.MoveConfidence = exports.HintGeneratorMode = void 0;
const card_1 = require("./card");
const desk_1 = require("./desk");
const move_1 = require("./move");
const util_1 = require("./util");
var HintGeneratorMode;
(function (HintGeneratorMode) {
    HintGeneratorMode["CURRENT_STATE"] = "HintGeneratorMode.CURRENT_STATE";
    HintGeneratorMode["WITH_FULL_STOCK"] = "HintGeneratorMode.WITH_FULL_STOCK";
})(HintGeneratorMode = exports.HintGeneratorMode || (exports.HintGeneratorMode = {}));
var MoveConfidence;
(function (MoveConfidence) {
    MoveConfidence["ABSOLUTE"] = "MoveConfidence.ABSOLUTE";
    MoveConfidence["VERY_HIGH"] = "MoveConfidence.VERY_HIGH";
    MoveConfidence["HIGH"] = "MoveConfidence.HIGH";
    MoveConfidence["MEDIUM"] = "MoveConfidence.MEDIUM";
    MoveConfidence["LOW"] = "MoveConfidence.LOW";
    MoveConfidence["VERY_LOW"] = "MoveConfidence.VERY_LOW";
    MoveConfidence["MINISCULE"] = "MoveConfidence.MINISCULE";
})(MoveConfidence = exports.MoveConfidence || (exports.MoveConfidence = {}));
exports.MOVE_CONFIDENCES = [
    MoveConfidence.ABSOLUTE,
    MoveConfidence.VERY_HIGH,
    MoveConfidence.HIGH,
    MoveConfidence.MEDIUM,
    MoveConfidence.LOW,
    MoveConfidence.VERY_LOW,
    MoveConfidence.MINISCULE,
];
function getMoveHints(desk, rules, mode) {
    const stockPlayableCards = getStockPlayableCards(desk, rules, mode);
    const { foundation, tableau } = desk;
    const topTableauCards = tableau.piles.filter((pile) => pile.cards.length).map((pile) => (0, util_1.lastItem)(pile.cards));
    const topFoundationCards = {
        [card_1.Color.DIAMONDS]: (0, util_1.lastItemOrNull)(foundation[card_1.Color.DIAMONDS].cards),
        [card_1.Color.HEARTS]: (0, util_1.lastItemOrNull)(foundation[card_1.Color.HEARTS].cards),
        [card_1.Color.CLUBS]: (0, util_1.lastItemOrNull)(foundation[card_1.Color.CLUBS].cards),
        [card_1.Color.SPADES]: (0, util_1.lastItemOrNull)(foundation[card_1.Color.SPADES].cards),
    };
    const moves = [];
    moves.push(...getMovesWithAbsoluteConfidence(desk, stockPlayableCards, topTableauCards, topFoundationCards));
    moves.push(...getMovesWithVeryHighConfidence(desk, stockPlayableCards));
    moves.push(...getMovesWithHighConfidence(desk, stockPlayableCards));
    moves.push(...getMovesWithMediumConfidence(desk));
    moves.push(...getMovesWithLowConfidence(desk));
    moves.push(...getMovesWithVeryLowConfidence(desk, stockPlayableCards, topFoundationCards, rules));
    moves.push(...getMovesWithMinisculeConfidence(desk, rules, stockPlayableCards, topFoundationCards));
    return filterFoundationToTableauHints(filterDuplicateHints(moves));
}
exports.getMoveHints = getMoveHints;
function filterFoundationToTableauHints(hints) {
    const hintsWithoutTransfersFromFoundation = hints.filter(([move]) => move.move !== move_1.MoveType.FOUNDATION_TO_TABLEAU);
    if (hintsWithoutTransfersFromFoundation.length && hintsWithoutTransfersFromFoundation.length !== hints.length) {
        return hintsWithoutTransfersFromFoundation;
    }
    return hints;
}
function filterDuplicateHints(hints) {
    const filteredHints = [];
    for (const hint of hints) {
        const isHintAlreadyPresent = filteredHints.some((otherHint) => areObjectsShallowlyEqual(hint[0], otherHint[0]) &&
            hint[1] === otherHint[1]);
        if (!isHintAlreadyPresent) {
            filteredHints.push(hint);
        }
    }
    return filteredHints;
    function areObjectsShallowlyEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        return keys1.every((key) => object1[key] === object2[key]);
    }
}
function getMovesWithAbsoluteConfidence(desk, stockPlayableCards, topTableauCards, topFoundationCards) {
    var _a, _b;
    const { tableau } = desk;
    const moves = [];
    // Revealing a card
    const cardToReveal = topTableauCards.find((card) => card.side === card_1.Side.BACK);
    if (cardToReveal) {
        moves.push([
            {
                move: move_1.MoveType.REVEAL_TABLEAU_CARD,
                pileIndex: getPileIndex(tableau, cardToReveal),
            },
            cardToReveal,
            MoveConfidence.ABSOLUTE,
        ]);
    }
    // Ace to foundation
    for (const card of topTableauCards) {
        if (card.side === card_1.Side.FACE && card.rank === card_1.Rank.ACE) {
            moves.push([
                {
                    move: move_1.MoveType.TABLEAU_TO_FOUNDATION,
                    pileIndex: getPileIndex(tableau, card),
                },
                card,
                MoveConfidence.ABSOLUTE,
            ]);
        }
    }
    for (const card of stockPlayableCards) {
        if (card.rank === card_1.Rank.ACE) {
            moves.push([
                {
                    move: move_1.MoveType.WASTE_TO_FOUNDATION,
                },
                card,
                MoveConfidence.ABSOLUTE,
            ]);
        }
    }
    // Two to foundation
    for (const card of topTableauCards) {
        if (card.side === card_1.Side.FACE && card.rank === card_1.Rank.TWO && ((_a = topFoundationCards[card.color]) === null || _a === void 0 ? void 0 : _a.rank) === card_1.Rank.ACE) {
            moves.push([
                {
                    move: move_1.MoveType.TABLEAU_TO_FOUNDATION,
                    pileIndex: getPileIndex(tableau, card),
                },
                card,
                MoveConfidence.ABSOLUTE,
            ]);
        }
    }
    for (const card of stockPlayableCards) {
        if (card.rank === card_1.Rank.TWO && ((_b = topFoundationCards[card.color]) === null || _b === void 0 ? void 0 : _b.rank) === card_1.Rank.ACE) {
            moves.push([
                {
                    move: move_1.MoveType.WASTE_TO_FOUNDATION,
                },
                card,
                MoveConfidence.ABSOLUTE,
            ]);
        }
    }
    // Finishing the game
    if ((0, desk_1.isVictoryGuaranteed)(desk)) {
        for (const card of topTableauCards) {
            const topFoundationCard = topFoundationCards[card.color];
            if (topFoundationCard && (0, card_1.isValidFoundationSequence)(topFoundationCard, card)) {
                moves.push([
                    {
                        move: move_1.MoveType.TABLEAU_TO_FOUNDATION,
                        pileIndex: getPileIndex(tableau, card),
                    },
                    card,
                    MoveConfidence.ABSOLUTE,
                ]);
            }
        }
    }
    return moves;
}
function getMovesWithVeryHighConfidence(desk, stockPlayableCards) {
    const { tableau } = desk;
    const moves = [];
    // Tableau to tableau transfer that allows revealing a card, source is not 5, 6, 7 or 8 and target pile is not empty
    const pilesFromLargestToSmallest = tableau.piles.slice().sort((pile1, pile2) => pile2.cards.length - pile1.cards.length);
    for (const pile of pilesFromLargestToSmallest) {
        const mostBottomRevealedCardIndex = pile.cards.findIndex((card) => card.side === card_1.Side.FACE);
        const sourceCard = pile.cards[mostBottomRevealedCardIndex];
        if (mostBottomRevealedCardIndex < 1 ||
            [card_1.Rank.FIVE, card_1.Rank.SIX, card_1.Rank.SEVEN, card_1.Rank.EIGHT].includes(sourceCard.rank)) {
            continue;
        }
        const targetPileIndex = tableau.piles.findIndex((targetPile) => (targetPile.cards.length &&
            (0, util_1.lastItem)(targetPile.cards).side === card_1.Side.FACE &&
            (0, card_1.isValidTableauSequence)((0, util_1.lastItem)(targetPile.cards), sourceCard)));
        if (targetPileIndex > -1) {
            moves.push([
                {
                    move: move_1.MoveType.TABLEAU_TO_TABLEAU,
                    sourcePileIndex: getPileIndex(tableau, sourceCard),
                    targetPileIndex,
                    topMovedCardIndex: mostBottomRevealedCardIndex,
                },
                sourceCard,
                MoveConfidence.VERY_HIGH,
            ]);
        }
    }
    // King to empty tableau pile transfer that allows transfer to the king's new pile that will reveal a new card
    const availableKings = tableau.piles
        // if the king is the top card in the pile, there is no point in making the transfer
        .filter((pile) => pile.cards.length > 1 && (pile.cards[0].side === card_1.Side.BACK || pile.cards[0].rank !== card_1.Rank.KING))
        .map((pile) => pile.cards.find((card) => card.rank === card_1.Rank.KING && card.side === card_1.Side.FACE))
        .filter((cardOrUndefined) => !!cardOrUndefined).map((card) => card)
        // tableau cards are preferred, so we'll put the stock cards at the end of the list
        .concat(stockPlayableCards.filter((card) => card.rank === card_1.Rank.KING));
    const emptyPileIndex = tableau.piles.findIndex((pile) => !pile.cards.length);
    if (availableKings.length && emptyPileIndex > -1) {
        for (const king of availableKings) {
            const kingSequenceEnd = stockPlayableCards.includes(king) ?
                king
                :
                    (0, util_1.lastItem)(tableau.piles[getPileIndex(tableau, king)].cards);
            const isBeneficialTransferToKingSequencePossible = tableau.piles.find((pile) => {
                const visibleCards = pile.cards.filter((card) => card.side === card_1.Side.FACE);
                return (pile.cards.length > visibleCards.length &&
                    visibleCards.length &&
                    (0, card_1.isValidTableauSequence)(kingSequenceEnd, visibleCards[0]));
            });
            if (isBeneficialTransferToKingSequencePossible) {
                const sourcePileIndex = getPileIndex(tableau, king);
                moves.push([
                    stockPlayableCards.includes(king) ?
                        {
                            move: move_1.MoveType.WASTE_TO_TABLEAU,
                            pileIndex: emptyPileIndex,
                        }
                        :
                            {
                                move: move_1.MoveType.TABLEAU_TO_TABLEAU,
                                sourcePileIndex,
                                targetPileIndex: emptyPileIndex,
                                topMovedCardIndex: tableau.piles[sourcePileIndex].cards.indexOf(king),
                            },
                    king,
                    MoveConfidence.VERY_HIGH,
                ]);
            }
        }
    }
    return moves;
}
function getMovesWithHighConfidence(desk, stockPlayableCards) {
    const moves = [];
    const { tableau } = desk;
    // King to an empty tableau pile transfer that allows moving the largest built sequence to the king's pile
    const availableKings = tableau.piles
        // if the king is the top card in the pile, there is no point in making the transfer
        .filter((pile) => pile.cards.length > 1 && (pile.cards[0].side === card_1.Side.BACK || pile.cards[0].rank !== card_1.Rank.KING))
        .map((pile) => pile.cards.find((card) => card.rank === card_1.Rank.KING && card.side === card_1.Side.FACE))
        .filter((cardOrUndefined) => !!cardOrUndefined).map((card) => card)
        // tableau cards are preferred, so we'll put the stock cards at the end of the list
        .concat(stockPlayableCards.filter((card) => card.rank === card_1.Rank.KING));
    const emptyPileIndex = tableau.piles.findIndex((pile) => !pile.cards.length);
    if (availableKings.length && emptyPileIndex > -1) {
        for (const king of availableKings) {
            const kingSequenceEnd = stockPlayableCards.includes(king) ?
                king
                :
                    (0, util_1.lastItem)(tableau.piles[getPileIndex(tableau, king)].cards);
            const largestSequences = tableau.piles
                .map((pile) => pile.cards.filter((card) => card.side === card_1.Side.FACE))
                .filter((pileCards) => pileCards.length)
                .map((pileCards, _, piles) => [pileCards, Math.max(...piles.map((pile) => pile.length))])
                .filter(([pileCards, largestSequenceLength]) => pileCards.length === largestSequenceLength)
                .map(([pileCards]) => pileCards);
            const isBeneficialTransferToKingSequencePossible = largestSequences.find((cardSequence) => (0, card_1.isValidTableauSequence)(kingSequenceEnd, cardSequence[0]));
            if (isBeneficialTransferToKingSequencePossible) {
                const sourcePileIndex = getPileIndex(tableau, king);
                moves.push([
                    stockPlayableCards.includes(king) ?
                        {
                            move: move_1.MoveType.WASTE_TO_TABLEAU,
                            pileIndex: emptyPileIndex,
                        }
                        :
                            {
                                move: move_1.MoveType.TABLEAU_TO_TABLEAU,
                                sourcePileIndex,
                                targetPileIndex: emptyPileIndex,
                                topMovedCardIndex: tableau.piles[sourcePileIndex].cards.indexOf(king),
                            },
                    king,
                    MoveConfidence.HIGH,
                ]);
            }
        }
    }
    return moves;
}
function getMovesWithMediumConfidence(desk) {
    const moves = [];
    const { tableau } = desk;
    // Moving a 5, 6, 7 or 8 from a tableau pile that (probably) has not been touched yet and will reveal a card to
    // another tableau pile
    const candidateCards = tableau.piles
        .filter((pile) => pile.cards.length > 1)
        .filter((pile) => pile.cards.filter((card) => card.side === card_1.Side.FACE).length === 1)
        .map((pile) => (0, util_1.lastItem)(pile.cards))
        .filter((card) => [card_1.Rank.FIVE, card_1.Rank.SIX, card_1.Rank.SEVEN, card_1.Rank.EIGHT].includes(card.rank));
    for (const candidateCard of candidateCards) {
        const sourcePileIndex = getPileIndex(tableau, candidateCard);
        const candidateTargets = tableau.piles.filter((pile) => pile.cards.length &&
            (0, util_1.lastItem)(pile.cards).side === card_1.Side.FACE &&
            (0, card_1.isValidTableauSequence)((0, util_1.lastItem)(pile.cards), candidateCard));
        for (const candidateTarget of candidateTargets) {
            moves.push([
                {
                    move: move_1.MoveType.TABLEAU_TO_TABLEAU,
                    sourcePileIndex,
                    targetPileIndex: tableau.piles.indexOf(candidateTarget),
                    topMovedCardIndex: tableau.piles[sourcePileIndex].cards.indexOf(candidateCard),
                },
                candidateCard,
                MoveConfidence.MEDIUM,
            ]);
        }
    }
    return moves;
}
function getMovesWithLowConfidence(desk) {
    const moves = [];
    const { tableau } = desk;
    // Making a transfer from tableau pile to a non-empty tableau pile that will reveal a card
    const pilesOfVisibleCards = tableau.piles.map((pile) => pile.cards.filter((card) => card.side === card_1.Side.FACE)).filter((pile) => pile.length);
    const candidateSourcePiles = pilesOfVisibleCards.filter((pile) => tableau.piles[getPileIndex(tableau, pile[0])].cards[0].side === card_1.Side.BACK);
    for (const pile of candidateSourcePiles) {
        const topSourceCard = pile[0];
        for (const otherPile of pilesOfVisibleCards) {
            const bottomTargetCard = (0, util_1.lastItem)(otherPile);
            if ((0, card_1.isValidTableauSequence)(bottomTargetCard, topSourceCard)) {
                const sourcePileIndex = getPileIndex(tableau, topSourceCard);
                moves.push([
                    {
                        move: move_1.MoveType.TABLEAU_TO_TABLEAU,
                        sourcePileIndex,
                        targetPileIndex: getPileIndex(tableau, bottomTargetCard),
                        topMovedCardIndex: tableau.piles[sourcePileIndex].cards.indexOf(topSourceCard),
                    },
                    topSourceCard,
                    MoveConfidence.LOW,
                ]);
            }
        }
    }
    // Tableau to tableau transfers that leaves the source pile empty, but there is a king that can be transferred there
    const pilesOfOnlyVisibleCards = tableau.piles.filter((pile) => pile.cards.length && pile.cards.every((card) => card.side === card_1.Side.FACE));
    const pilesWithKing = tableau.piles.filter((pile) => pile.cards.some((card) => card.side === card_1.Side.FACE && card.rank === card_1.Rank.KING));
    if (pilesWithKing.length) {
        for (const sourcePile of pilesOfOnlyVisibleCards) {
            const topSourceCard = sourcePile.cards[0];
            for (const otherPile of pilesOfVisibleCards) {
                const bottomTargetCard = (0, util_1.lastItem)(otherPile);
                if ((0, card_1.isValidTableauSequence)(bottomTargetCard, topSourceCard)) {
                    const sourcePileIndex = getPileIndex(tableau, topSourceCard);
                    moves.push([
                        {
                            move: move_1.MoveType.TABLEAU_TO_TABLEAU,
                            sourcePileIndex,
                            targetPileIndex: getPileIndex(tableau, bottomTargetCard),
                            topMovedCardIndex: tableau.piles[sourcePileIndex].cards.indexOf(topSourceCard),
                        },
                        topSourceCard,
                        MoveConfidence.LOW,
                    ]);
                }
            }
        }
    }
    return moves;
}
function getMovesWithVeryLowConfidence(desk, stockPlayableCards, topFoundationCards, rules) {
    const moves = [];
    const { tableau } = desk;
    const filteredTopFoundationCards = Object.values(topFoundationCards).filter((card) => card);
    // Foundation to tableau transfer that allows revealing a card by tableau to tableau transfer
    for (const foundationCard of filteredTopFoundationCards) {
        const isTherePileTransferableToCard = tableau.piles.some((pile) => {
            const highestVisibleCard = pile.cards.find((card) => card.side === card_1.Side.FACE);
            return (highestVisibleCard &&
                pile.cards[0] !== highestVisibleCard &&
                (0, card_1.isValidTableauSequence)(foundationCard, highestVisibleCard));
        });
        if (!isTherePileTransferableToCard) {
            continue;
        }
        const targetPileIndex = tableau.piles.findIndex((pile) => {
            const lastCard = (0, util_1.lastItemOrNull)(pile.cards);
            return lastCard && lastCard.side === card_1.Side.FACE && (0, card_1.isValidTableauSequence)(lastCard, foundationCard);
        });
        if (targetPileIndex > -1) {
            moves.push([
                {
                    color: foundationCard.color,
                    move: move_1.MoveType.FOUNDATION_TO_TABLEAU,
                    pileIndex: targetPileIndex,
                },
                foundationCard,
                MoveConfidence.VERY_LOW,
            ]);
        }
    }
    // Foundation to tableau transfer that allows a stock to tableau transfer
    for (const foundationCard of filteredTopFoundationCards) {
        const isThereMatchingStockCard = stockPlayableCards.some((card) => (0, card_1.isValidTableauSequence)(foundationCard, card));
        if (!isThereMatchingStockCard) {
            continue;
        }
        const targetPileIndex = tableau.piles.findIndex((pile) => {
            const lastCard = (0, util_1.lastItemOrNull)(pile.cards);
            return lastCard && lastCard.side === card_1.Side.FACE && (0, card_1.isValidTableauSequence)(lastCard, foundationCard);
        });
        if (targetPileIndex > -1) {
            moves.push([
                {
                    color: foundationCard.color,
                    move: move_1.MoveType.FOUNDATION_TO_TABLEAU,
                    pileIndex: targetPileIndex,
                },
                foundationCard,
                MoveConfidence.VERY_LOW,
            ]);
        }
    }
    const emptyPileIndex = tableau.piles.findIndex((candidatePile) => !candidatePile.cards.length);
    // King to empty pile transfer that reveals a new card
    for (const pile of tableau.piles) {
        const kingIndex = pile.cards.findIndex((card) => card.side === card_1.Side.FACE && card.rank === card_1.Rank.KING);
        const king = pile.cards[kingIndex];
        if (king && kingIndex > 0 && pile.cards[kingIndex - 1].side === card_1.Side.BACK && emptyPileIndex > -1) {
            moves.push([
                {
                    move: move_1.MoveType.TABLEAU_TO_TABLEAU,
                    sourcePileIndex: getPileIndex(tableau, king),
                    targetPileIndex: emptyPileIndex,
                    topMovedCardIndex: kingIndex,
                },
                king,
                MoveConfidence.VERY_LOW,
            ]);
        }
    }
    // Tableau to tableau transfers that leaves the source pile empty
    const pilesOfOnlyVisibleCards = tableau.piles.filter((pile) => pile.cards.length && pile.cards.every((card) => card.side === card_1.Side.FACE));
    const pilesWithVisibleCards = tableau.piles.filter((pile) => pile.cards.some((card) => card.side === card_1.Side.FACE));
    for (const sourcePile of pilesOfOnlyVisibleCards) {
        const topSourceCard = sourcePile.cards[0];
        for (const otherPile of pilesWithVisibleCards) {
            const bottomTargetCard = (0, util_1.lastItem)(otherPile.cards);
            if ((0, card_1.isValidTableauSequence)(bottomTargetCard, topSourceCard)) {
                const sourcePileIndex = getPileIndex(tableau, topSourceCard);
                moves.push([
                    {
                        move: move_1.MoveType.TABLEAU_TO_TABLEAU,
                        sourcePileIndex,
                        targetPileIndex: getPileIndex(tableau, bottomTargetCard),
                        topMovedCardIndex: tableau.piles[sourcePileIndex].cards.indexOf(topSourceCard),
                    },
                    topSourceCard,
                    MoveConfidence.VERY_LOW,
                ]);
            }
        }
    }
    // Stock to tableau
    for (const card of stockPlayableCards) {
        for (const pile of tableau.piles) {
            const lastCard = (0, util_1.lastItemOrNull)(pile.cards);
            if (lastCard && lastCard.side === card_1.Side.FACE && (0, card_1.isValidTableauSequence)(lastCard, card)) {
                moves.push([
                    {
                        move: move_1.MoveType.WASTE_TO_TABLEAU,
                        pileIndex: getPileIndex(tableau, lastCard),
                    },
                    card,
                    MoveConfidence.VERY_LOW,
                ]);
            }
        }
    }
    // Stock to foundation
    for (const stockCard of stockPlayableCards) {
        for (const foundationCard of filteredTopFoundationCards) {
            if ((0, card_1.isValidFoundationSequence)(foundationCard, stockCard)) {
                moves.push([
                    {
                        move: move_1.MoveType.WASTE_TO_FOUNDATION,
                    },
                    stockCard,
                    MoveConfidence.VERY_LOW,
                ]);
            }
        }
    }
    // Card from waste/stock to an empty tableau pile transfer
    if (emptyPileIndex > -1) {
        const usableCards = rules.allowNonKingToEmptyPileTransfer ?
            stockPlayableCards
            :
                stockPlayableCards.filter((card) => card.rank === card_1.Rank.KING);
        for (const card of usableCards) {
            moves.push([
                {
                    move: move_1.MoveType.WASTE_TO_TABLEAU,
                    pileIndex: emptyPileIndex,
                },
                card,
                MoveConfidence.VERY_LOW,
            ]);
        }
    }
    return moves;
}
function getMovesWithMinisculeConfidence(desk, rules, stockPlayableCards, topFoundationCards) {
    const moves = [];
    const { tableau } = desk;
    // Tableau to foundation transfer that allows a transfer revealing card or reveal a card
    for (const pile of tableau.piles) {
        const lastCard = (0, util_1.lastItemOrNull)(pile.cards);
        if (!lastCard || lastCard.side === card_1.Side.BACK) {
            continue;
        }
        const foundationCard = topFoundationCards[lastCard.color];
        if (!foundationCard || !(0, card_1.isValidFoundationSequence)(foundationCard, lastCard)) {
            continue;
        }
        const nextToLastCard = pile.cards.slice(-2)[0];
        // Will the transfer reveal a card?
        if (pile.cards.length > 1 && nextToLastCard.side === card_1.Side.BACK) {
            moves.push([
                {
                    move: move_1.MoveType.TABLEAU_TO_FOUNDATION,
                    pileIndex: getPileIndex(tableau, lastCard),
                },
                lastCard,
                MoveConfidence.MINISCULE,
            ]);
            continue;
        }
        // Will the transfer allow a transfer that will reveal a card?
        for (const otherPile of tableau.piles) {
            const highestVisibleCard = otherPile.cards.find((card) => card.side === card_1.Side.FACE);
            if (highestVisibleCard &&
                otherPile.cards[0] !== highestVisibleCard &&
                (0, card_1.isValidTableauSequence)(nextToLastCard, highestVisibleCard)) {
                moves.push([
                    {
                        move: move_1.MoveType.TABLEAU_TO_FOUNDATION,
                        pileIndex: getPileIndex(tableau, lastCard),
                    },
                    lastCard,
                    MoveConfidence.MINISCULE,
                ]);
            }
        }
    }
    // Tableau to foundation transfer that that allows a waste to tableau or foundation transfer
    for (const pile of tableau.piles) {
        const lastCard = (0, util_1.lastItemOrNull)(pile.cards);
        if (!lastCard || lastCard.side === card_1.Side.BACK) {
            continue;
        }
        const foundationCard = topFoundationCards[lastCard.color];
        if (!foundationCard || !(0, card_1.isValidFoundationSequence)(foundationCard, lastCard)) {
            continue;
        }
        // Will the transfer allow a waste to foundation transfer?
        for (const stockCard of stockPlayableCards) {
            if ((0, card_1.isValidFoundationSequence)(lastCard, stockCard)) {
                moves.push([
                    {
                        move: move_1.MoveType.TABLEAU_TO_FOUNDATION,
                        pileIndex: getPileIndex(tableau, lastCard),
                    },
                    lastCard,
                    MoveConfidence.MINISCULE,
                ]);
            }
        }
        // Will the transfer allow a waste to tableau transfer?
        if (pile.cards.length <= 1) {
            continue;
        }
        const nextToLastCard = pile.cards.slice(-2)[0];
        if (nextToLastCard.side === card_1.Side.FACE) {
            for (const stockCard of stockPlayableCards) {
                if ((0, card_1.isValidTableauSequence)(nextToLastCard, stockCard)) {
                    moves.push([
                        {
                            move: move_1.MoveType.TABLEAU_TO_FOUNDATION,
                            pileIndex: getPileIndex(tableau, lastCard),
                        },
                        lastCard,
                        MoveConfidence.MINISCULE,
                    ]);
                }
            }
        }
    }
    const emptyPileIndex = tableau.piles.findIndex((pile) => !pile.cards.length);
    // Stock to empty pile transfer, prefer higher-ranking cards - applies only if allowed by game rules
    if (rules.allowNonKingToEmptyPileTransfer && emptyPileIndex > -1) {
        for (const card of stockPlayableCards.slice().sort((card1, card2) => (0, card_1.compareRank)(card2, card1))) {
            moves.push([
                {
                    move: move_1.MoveType.WASTE_TO_TABLEAU,
                    pileIndex: emptyPileIndex,
                },
                card,
                MoveConfidence.MINISCULE,
            ]);
        }
    }
    // Tableau to empty pile transfer that allows revealing a card - applies only if allowed by game rules
    if (rules.allowNonKingToEmptyPileTransfer && emptyPileIndex > -1) {
        const sourcePiles = tableau.piles.filter((pile) => pile.cards.length && pile.cards[0].side === card_1.Side.BACK && (0, util_1.lastItem)(pile.cards).side === card_1.Side.FACE).sort((pile1, pile2) => (pile2.cards.filter((card) => card.side === card_1.Side.FACE).length -
            pile1.cards.filter((card) => card.side === card_1.Side.FACE).length));
        for (const pile of sourcePiles) {
            const topCardIndex = pile.cards.findIndex((card) => card.side === card_1.Side.FACE);
            moves.push([
                {
                    move: move_1.MoveType.TABLEAU_TO_TABLEAU,
                    sourcePileIndex: getPileIndex(tableau, pile.cards[topCardIndex]),
                    targetPileIndex: emptyPileIndex,
                    topMovedCardIndex: topCardIndex,
                },
                pile.cards[topCardIndex],
                MoveConfidence.MINISCULE,
            ]);
        }
    }
    // Tableau to a non-empty foundation pile (Ace to empty foundation is already covered with absolute confidence)
    for (const pile of tableau.piles) {
        if (!pile.cards.length) {
            continue;
        }
        const lastCard = (0, util_1.lastItem)(pile.cards);
        const foundationCard = topFoundationCards[lastCard.color];
        if (foundationCard && (0, card_1.isValidFoundationSequence)(foundationCard, lastCard)) {
            moves.push([
                {
                    move: move_1.MoveType.TABLEAU_TO_FOUNDATION,
                    pileIndex: getPileIndex(tableau, lastCard),
                },
                lastCard,
                MoveConfidence.MINISCULE,
            ]);
        }
    }
    return moves;
}
function getStockPlayableCards(deskState, rules, mode) {
    switch (mode) {
        case HintGeneratorMode.CURRENT_STATE:
            return deskState.waste.cards.length ? [(0, util_1.lastItem)(deskState.waste.cards)] : [];
        case HintGeneratorMode.WITH_FULL_STOCK: {
            if (!deskState.waste.cards.length && !deskState.stock.cards.length) {
                return [];
            }
            const stock = deskState.stock.cards.slice();
            const waste = deskState.waste.cards.slice();
            const cards = new Set();
            if (waste.length) {
                cards.add((0, util_1.lastItem)(waste));
                // Iterate through the remaining stock
                for (let cardIndex = stock.length - rules.drawnCards; cardIndex >= 0; cardIndex -= rules.drawnCards) {
                    cards.add((0, card_1.turnOver)(stock[cardIndex]));
                }
            }
            stock.push(...waste.reverse().map(card_1.turnOver)); // redeal
            // Iterate through the stock
            for (let cardIndex = stock.length - rules.drawnCards; cardIndex >= 0; cardIndex -= rules.drawnCards) {
                cards.add((0, card_1.turnOver)(stock[cardIndex]));
            }
            return [...cards];
        }
        default:
            throw new TypeError(`Unknown hint generator mode: ${mode}`);
    }
}
function getPileIndex(tableau, card) {
    return tableau.piles.findIndex((pile) => pile.cards.includes(card));
}
