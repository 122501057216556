"use strict";
// see https://en.wikipedia.org/wiki/Standard_52-card_deck
Object.defineProperty(exports, "__esModule", { value: true });
exports.equals = exports.turnOver = exports.compareRank = exports.isValidFoundationSequence = exports.isValidTableauSequence = exports.DECK = exports.Card = exports.Side = exports.RANK_SEQUENCE = exports.Rank = exports.RED_COLORS = exports.Color = void 0;
var Color;
(function (Color) {
    Color["SPADES"] = "Color_SPADES";
    Color["HEARTS"] = "Color_HEARTS";
    Color["DIAMONDS"] = "Color_DIAMONDS";
    Color["CLUBS"] = "Color_CLUBS";
})(Color = exports.Color || (exports.Color = {}));
exports.RED_COLORS = [
    Color.HEARTS,
    Color.DIAMONDS,
];
var Rank;
(function (Rank) {
    Rank["ACE"] = "Rank_ACE";
    Rank["TWO"] = "Rank_TWO";
    Rank["THREE"] = "Rank_THREE";
    Rank["FOUR"] = "Rank_FOUR";
    Rank["FIVE"] = "Rank_FIVE";
    Rank["SIX"] = "Rank_SIX";
    Rank["SEVEN"] = "Rank_SEVEN";
    Rank["EIGHT"] = "Rank_EIGHT";
    Rank["NINE"] = "Rank_NINE";
    Rank["TEN"] = "Rank_TEN";
    Rank["JACK"] = "Rank_JACK";
    Rank["QUEEN"] = "Rank_QUEEN";
    Rank["KING"] = "Rank_KING";
})(Rank = exports.Rank || (exports.Rank = {}));
exports.RANK_SEQUENCE = [
    Rank.ACE,
    Rank.TWO,
    Rank.THREE,
    Rank.FOUR,
    Rank.FIVE,
    Rank.SIX,
    Rank.SEVEN,
    Rank.EIGHT,
    Rank.NINE,
    Rank.TEN,
    Rank.JACK,
    Rank.QUEEN,
    Rank.KING,
];
var Side;
(function (Side) {
    Side["FACE"] = "Side_FACE";
    Side["BACK"] = "Side_BACK";
})(Side = exports.Side || (exports.Side = {}));
class Card {
    constructor(color, rank, side) {
        this.color = color;
        this.rank = rank;
        this.side = side;
    }
}
exports.Card = Card;
exports.DECK = [].concat(...Object.values(Color).map((color) => exports.RANK_SEQUENCE.map((rank) => new Card(color, rank, Side.BACK))));
function isValidTableauSequence(cardOnPile, cardToAdd) {
    return compareRank(cardOnPile, cardToAdd) === 1 && !isSameColorInFrenchDeck(cardOnPile, cardToAdd);
}
exports.isValidTableauSequence = isValidTableauSequence;
function isValidFoundationSequence(cardOnFoundation, cardToAdd) {
    return compareRank(cardOnFoundation, cardToAdd) === -1 && cardOnFoundation.color === cardToAdd.color;
}
exports.isValidFoundationSequence = isValidFoundationSequence;
function compareRank(card1, card2) {
    const card1NumericRank = exports.RANK_SEQUENCE.indexOf(card1.rank);
    const card2NumericRank = exports.RANK_SEQUENCE.indexOf(card2.rank);
    return card1NumericRank - card2NumericRank;
}
exports.compareRank = compareRank;
function turnOver(card) {
    return new Card(card.color, card.rank, card.side === Side.FACE ? Side.BACK : Side.FACE);
}
exports.turnOver = turnOver;
function equals(card1, card2) {
    return card1.color === card2.color && card1.rank === card2.rank;
}
exports.equals = equals;
// https://en.wikipedia.org/wiki/French_playing_cards
function isSameColorInFrenchDeck(card1, card2) {
    const isCard1Red = exports.RED_COLORS.includes(card1.color);
    const isCard2Red = exports.RED_COLORS.includes(card2.color);
    return isCard1Red === isCard2Red;
}
