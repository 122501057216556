"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMoveCount = exports.getGameplayDuration = exports.isVictoryGuaranteed = exports.isVictory = exports.redoNextMove = exports.undoLastMove = exports.resetGame = exports.executeMove = exports.createGameWithBotPredicate = exports.createNewGame = void 0;
const bot_1 = require("./bot");
const card_1 = require("./card");
const desk_1 = require("./desk");
const move_1 = require("./move");
const pile_1 = require("./pile");
const tableau_1 = require("./tableau");
const util_1 = require("./util");
function createNewGame(gameRules, cardDeck = null) {
    if (!Number.isSafeInteger(gameRules.drawnCards) || gameRules.drawnCards <= 0) {
        throw new TypeError(`The drawnCards game rule must be a positive safe integer, ${gameRules.drawnCards} was provided`);
    }
    if (!Number.isSafeInteger(gameRules.tableauPiles) || gameRules.tableauPiles <= 0) {
        throw new TypeError(`The tableauPiles game rule must be a positive safe integer, ${gameRules.tableauPiles} was provided`);
    }
    let cardsToDeal = cardDeck ? new pile_1.Pile(cardDeck) : (0, pile_1.shuffle)(new pile_1.Pile(card_1.DECK));
    for (const card of cardsToDeal.cards) {
        if (card.side === card_1.Side.FACE) {
            cardsToDeal = (0, pile_1.turnCard)(cardsToDeal, card);
        }
    }
    const piles = [];
    for (let i = 0; i < gameRules.tableauPiles; i++) {
        const [remainingCards, cardsForPile] = (0, pile_1.draw)(cardsToDeal, i + 1);
        const currentPile = new pile_1.Pile(cardsForPile);
        if (currentPile.cards.length) {
            piles.push((0, pile_1.turnCard)(currentPile, (0, util_1.lastItem)(currentPile.cards)));
        }
        else {
            piles.push(currentPile);
        }
        cardsToDeal = remainingCards;
    }
    return {
        future: [],
        history: [],
        rules: {
            allowNonKingToEmptyPileTransfer: gameRules.allowNonKingToEmptyPileTransfer,
            drawnCards: gameRules.drawnCards,
        },
        startTime: {
            absoluteTimestamp: Date.now(),
            logicalTimestamp: performance.now(),
        },
        state: new desk_1.Desk(cardsToDeal, new pile_1.Pile([]), {
            [card_1.Color.DIAMONDS]: new pile_1.Pile([]),
            [card_1.Color.HEARTS]: new pile_1.Pile([]),
            [card_1.Color.CLUBS]: new pile_1.Pile([]),
            [card_1.Color.SPADES]: new pile_1.Pile([]),
        }, new tableau_1.Tableau(piles)),
    };
}
exports.createNewGame = createNewGame;
function createGameWithBotPredicate(rules, botOptions, { maxMoves, maxSimulationTime, simulationEndPredicate }) {
    const game = createNewGame(rules);
    let lastDeskState = game.state;
    let satisfiesPredicate = false;
    let moveCount = 0;
    const startTimestamp = performance.now();
    do {
        const newDeskState = (0, bot_1.makeMoveOnDesk)(lastDeskState, rules, botOptions);
        moveCount++;
        if (newDeskState === lastDeskState || moveCount >= maxMoves || simulationEndPredicate(newDeskState)) {
            satisfiesPredicate = simulationEndPredicate(newDeskState);
            break;
        }
        if (!(moveCount % 10) && performance.now() - startTimestamp >= maxSimulationTime) {
            satisfiesPredicate = simulationEndPredicate(newDeskState); // for code consistency, but will be false
            break;
        }
        lastDeskState = newDeskState;
    } while (true);
    return [game, satisfiesPredicate];
}
exports.createGameWithBotPredicate = createGameWithBotPredicate;
function executeMove(game, move) {
    const updatedDesk = move.move === move_1.MoveType.UNDO ?
        executeUndoMove(game)
        :
            (0, desk_1.executeMove)(game.state, game.rules, move);
    return createNextGameState(game, updatedDesk, move);
}
exports.executeMove = executeMove;
function resetGame(game) {
    return Object.assign(Object.assign({}, game), { future: [], history: [], startTime: {
            absoluteTimestamp: Date.now(),
            logicalTimestamp: performance.now(),
        }, state: game.history.length ? game.history[0][0] : game.state });
}
exports.resetGame = resetGame;
function undoLastMove(game) {
    if (!game.history.length) {
        return game;
    }
    const newHistory = game.history.slice();
    const [moveToUndo] = newHistory.splice(-1);
    return Object.assign(Object.assign({}, game), { future: [moveToUndo, ...game.future], history: newHistory, state: moveToUndo[0] });
}
exports.undoLastMove = undoLastMove;
function redoNextMove(game) {
    if (!game.future.length) {
        return game;
    }
    const [moveToRedo, ...newFuture] = game.future;
    const newState = newFuture.length ?
        newFuture[0][0]
        :
            executeMove(Object.assign(Object.assign({}, game), { state: moveToRedo[0] }), moveToRedo[1]).state;
    return Object.assign(Object.assign({}, game), { future: newFuture, history: game.history.concat([moveToRedo]), state: newState });
}
exports.redoNextMove = redoNextMove;
function isVictory({ state }) {
    return (0, desk_1.isVictory)(state);
}
exports.isVictory = isVictory;
function isVictoryGuaranteed({ state }) {
    return (0, desk_1.isVictoryGuaranteed)(state);
}
exports.isVictoryGuaranteed = isVictoryGuaranteed;
function getGameplayDuration(game) {
    var _a;
    const endTimestamp = isVictory(game) ? (0, util_1.lastItem)(game.history)[1].logicalTimestamp : performance.now();
    const { previousTimestamp: lastTimestamp, sum } = game.history.reduce(({ previousTimestamp, sum: partialSum }, [, move]) => {
        const { move: moveType, logicalTimestamp } = move;
        const timeDelta = moveType === move_1.MoveType.RESUME ? 0 : logicalTimestamp - previousTimestamp;
        return {
            previousTimestamp: logicalTimestamp,
            sum: partialSum + timeDelta,
        };
    }, {
        previousTimestamp: game.startTime.logicalTimestamp,
        sum: 0,
    });
    return ((_a = (0, util_1.lastItemOrNull)(game.history)) === null || _a === void 0 ? void 0 : _a[1].move) !== move_1.MoveType.PAUSE ? sum + (endTimestamp - lastTimestamp) : sum;
}
exports.getGameplayDuration = getGameplayDuration;
function createNextGameState(game, nextState, appliedMove) {
    return Object.assign(Object.assign({}, game), { future: [], history: game.history.concat([[
                game.state,
                Object.assign(Object.assign({}, appliedMove), { logicalTimestamp: performance.now() }),
            ]]), state: nextState });
}
const MOVES_OMITTED_FROM_MOVE_COUNT = [
    move_1.MoveType.REVEAL_TABLEAU_CARD,
    move_1.MoveType.PAUSE,
    move_1.MoveType.RESUME,
];
function getMoveCount(games) {
    return games.history.filter((record) => !MOVES_OMITTED_FROM_MOVE_COUNT.includes(record[1].move)).length;
}
exports.getMoveCount = getMoveCount;
function executeUndoMove(game) {
    if (!game.history.length) {
        throw new Error('The provided game has no moves in its history');
    }
    const filteredHistory = [];
    for (const historyRecord of game.history) {
        if (MOVES_OMITTED_FROM_MOVE_COUNT.includes(historyRecord[1].move)) {
            continue;
        }
        if (historyRecord[1].move === move_1.MoveType.UNDO) {
            if (!filteredHistory.length) {
                throw new Error('The provided game has malformed history, there are too many undo moves in its history');
            }
            filteredHistory.pop();
        }
        else {
            filteredHistory.push(historyRecord);
        }
    }
    if (!filteredHistory.length) {
        throw new Error('The provided game has no moves in its history left to undo');
    }
    return (0, util_1.lastItem)(filteredHistory)[0];
}
