"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoveType = void 0;
var MoveType;
(function (MoveType) {
    MoveType["DRAW_CARDS"] = "MoveType.DRAW_CARDS";
    MoveType["REDEAL"] = "MoveType.REDEAL";
    MoveType["WASTE_TO_FOUNDATION"] = "MoveType.WASTE_TO_FOUNDATION";
    MoveType["WASTE_TO_TABLEAU"] = "MoveType.WASTE_TO_TABLEAU";
    MoveType["TABLEAU_TO_FOUNDATION"] = "MoveType.TABLEAU_TO_FOUNDATION";
    MoveType["REVEAL_TABLEAU_CARD"] = "MoveType.REVEAL_TABLEAU_CARD";
    MoveType["FOUNDATION_TO_TABLEAU"] = "MoveType.FOUNDATION_TO_TABLEAU";
    MoveType["TABLEAU_TO_TABLEAU"] = "MoveType.TABLEAU_TO_TABLEAU";
    MoveType["UNDO"] = "MoveType.UNDO";
    MoveType["PAUSE"] = "MoveType.PAUSE";
    MoveType["RESUME"] = "MoveType.RESUME";
})(MoveType = exports.MoveType || (exports.MoveType = {}));
