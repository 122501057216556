"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lastItem = exports.lastItemOrNull = void 0;
function lastItemOrNull(array) {
    return array.length ? lastItem(array) : null;
}
exports.lastItemOrNull = lastItemOrNull;
function lastItem(array) {
    if (array.length) {
        return array[array.length - 1];
    }
    throw new Error('The provided array is empty');
}
exports.lastItem = lastItem;
