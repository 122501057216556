"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isVictoryGuaranteed = exports.isVictory = exports.executeMove = exports.Desk = void 0;
const card_1 = require("./card");
const move_1 = require("./move");
const pile_1 = require("./pile");
const tableau_1 = require("./tableau");
const util_1 = require("./util");
class Desk {
    constructor(stock, waste, foundation, tableau) {
        this.stock = stock;
        this.waste = waste;
        this.foundation = foundation;
        this.tableau = tableau;
    }
}
exports.Desk = Desk;
function executeMove(desk, rules, move) {
    switch (move.move) {
        case move_1.MoveType.DRAW_CARDS:
            return drawCards(desk, rules, move.drawnCards);
        case move_1.MoveType.REDEAL:
            return redeal(desk);
        case move_1.MoveType.WASTE_TO_FOUNDATION:
            return moveTopWasteCardToFoundation(desk);
        case move_1.MoveType.WASTE_TO_TABLEAU:
            return moveTopWasteCardToTableau(desk, rules, desk.tableau.piles[move.pileIndex]);
        case move_1.MoveType.TABLEAU_TO_FOUNDATION:
            return moveTopTableauPileCardToFoundation(desk, desk.tableau.piles[move.pileIndex]);
        case move_1.MoveType.REVEAL_TABLEAU_CARD:
            return revealTopTableauPileCard(desk, desk.tableau.piles[move.pileIndex]);
        case move_1.MoveType.FOUNDATION_TO_TABLEAU:
            return moveFoundationCardToTableauPile(desk, rules, move.color, desk.tableau.piles[move.pileIndex]);
        case move_1.MoveType.TABLEAU_TO_TABLEAU:
            return moveTableauPilePart(desk, rules, desk.tableau.piles[move.sourcePileIndex], desk.tableau.piles[move.sourcePileIndex].cards[move.topMovedCardIndex], desk.tableau.piles[move.targetPileIndex]);
        case move_1.MoveType.UNDO:
            throw new Error('The UNDO move must be executed on the game itself, since a Desk does not keep track of its state history');
        case move_1.MoveType.PAUSE:
        case move_1.MoveType.RESUME:
            return desk;
        default:
            throw new Error(`Unknown move type: ${move && move.move}`);
    }
}
exports.executeMove = executeMove;
function isVictory({ stock, tableau, waste }) {
    return !stock.cards.length && !waste.cards.length && tableau.piles.every((pile) => !pile.cards.length);
}
exports.isVictory = isVictory;
function isVictoryGuaranteed({ stock, waste, tableau: { piles: tableauPiles } }) {
    return (!stock.cards.length &&
        !waste.cards.length &&
        tableauPiles.every((pile) => pile.cards.every((card) => card.side === card_1.Side.FACE)));
}
exports.isVictoryGuaranteed = isVictoryGuaranteed;
function drawCards(desk, rules, numberOfCards) {
    if (numberOfCards !== rules.drawnCards) {
        throw new Error(`The number of cards to draw (${numberOfCards}) does not match the number in game rules (${rules.drawnCards})`);
    }
    const [stockRemainder, drawnCards] = (0, pile_1.draw)(desk.stock, numberOfCards);
    const newWaste = (0, pile_1.placePileOnTop)(desk.waste, new pile_1.Pile(drawnCards.map((card) => (0, card_1.turnOver)(card))));
    return new Desk(stockRemainder, newWaste, desk.foundation, desk.tableau);
}
function redeal(desk) {
    if (desk.stock.cards.length) {
        throw new Error('Cannot redeal if there are cards in the stock');
    }
    return new Desk(new pile_1.Pile(desk.waste.cards.map((card) => (0, card_1.turnOver)(card)).reverse()), new pile_1.Pile([]), desk.foundation, desk.tableau);
}
function moveTopWasteCardToFoundation(desk) {
    if (!desk.waste.cards.length) {
        throw new Error('There is no card on the waste pile');
    }
    const [newWaste, [cardToPlace]] = (0, pile_1.draw)(desk.waste, 1);
    const newFoundation = addCardToFoundation(desk.foundation, cardToPlace);
    return new Desk(desk.stock, newWaste, newFoundation, desk.tableau);
}
function moveTopWasteCardToTableau(desk, rules, tableauPile) {
    if (!desk.waste.cards.length) {
        throw new Error('There is no card on the waste pile');
    }
    const [newWaste, [cardToPlace]] = (0, pile_1.draw)(desk.waste, 1);
    if (!tableauPile.cards.length &&
        !rules.allowNonKingToEmptyPileTransfer &&
        cardToPlace.rank !== card_1.Rank.KING) {
        throw new Error(`The current game rules forbid placing any card other than a King on an empty tableau pile`);
    }
    if (tableauPile.cards.length &&
        !(0, card_1.isValidTableauSequence)((0, util_1.lastItem)(tableauPile.cards), cardToPlace)) {
        throw new Error('The top waste card cannot be placed on top of the target tableau pile because it is not in rank sequence with ' +
            'the current top card of the target pile or it is of the same french deck color (red/black) as the current top ' +
            'card of the target pile');
    }
    const newTableau = (0, tableau_1.addCardToPile)(desk.tableau, tableauPile, cardToPlace);
    return new Desk(desk.stock, Object.assign(Object.assign({}, newWaste), { cards: [...newWaste.cards] }), desk.foundation, newTableau);
}
function moveTopTableauPileCardToFoundation(desk, tableauPile) {
    const [newTableau, cardToPlace] = (0, tableau_1.removeTopCardFromPile)(desk.tableau, tableauPile);
    const newFoundation = addCardToFoundation(desk.foundation, cardToPlace);
    return new Desk(desk.stock, desk.waste, newFoundation, newTableau);
}
function revealTopTableauPileCard(desk, tableauPile) {
    const newTableau = (0, tableau_1.revealTopCard)(desk.tableau, tableauPile);
    return new Desk(desk.stock, desk.waste, desk.foundation, newTableau);
}
function moveFoundationCardToTableauPile(desk, rules, color, tableauPile) {
    if (!desk.foundation[color].cards.length) {
        throw new Error(`The specified foundation (${color}) contains no cards`);
    }
    const [newFoundationPile, [cardToPlace]] = (0, pile_1.draw)(desk.foundation[color], 1);
    if (!tableauPile.cards.length &&
        !rules.allowNonKingToEmptyPileTransfer &&
        cardToPlace.rank !== card_1.Rank.KING) {
        throw new Error(`The current game rules forbid placing any card other than a King on an empty tableau pile`);
    }
    if (tableauPile.cards.length &&
        !(0, card_1.isValidTableauSequence)((0, util_1.lastItem)(tableauPile.cards), cardToPlace)) {
        throw new Error('The top foundation card cannot be placed on top of the target tableau pile because it is not in rank sequence ' +
            'with the current top card of the target pile or it is of the same french deck color (red/black) as the top ' +
            'card of the target pile');
    }
    const newTableau = (0, tableau_1.addCardToPile)(desk.tableau, tableauPile, cardToPlace);
    return new Desk(desk.stock, desk.waste, Object.assign(Object.assign({}, desk.foundation), { [cardToPlace.color]: newFoundationPile }), newTableau);
}
function moveTableauPilePart(desk, rules, sourcePile, topCardToMove, targetPile) {
    if (!targetPile.cards.length &&
        !rules.allowNonKingToEmptyPileTransfer &&
        topCardToMove.rank !== card_1.Rank.KING) {
        throw new Error(`The current game rules forbid placing any card other than a King on an empty tableau pile`);
    }
    if (targetPile.cards.length &&
        !(0, card_1.isValidTableauSequence)((0, util_1.lastItem)(targetPile.cards), topCardToMove)) {
        throw new Error(`The top moved card (${topCardToMove.rank} ${topCardToMove.color}) cannot be placed on top of the target ` +
            `tableau pile (${(0, util_1.lastItem)(targetPile.cards).rank} ${(0, util_1.lastItem)(targetPile.cards).color}) because it is not in ` +
            'rank sequence with the current top card of the target pile or it is of the same french deck color (red/black) ' +
            'as the current top card of the target pile');
    }
    const newTableau = (0, tableau_1.movePilePart)(desk.tableau, sourcePile, topCardToMove, targetPile);
    return new Desk(desk.stock, desk.waste, desk.foundation, newTableau);
}
function addCardToFoundation(foundation, cardToPlace) {
    const targetFoundationPile = foundation[cardToPlace.color];
    if (!targetFoundationPile.cards.length && cardToPlace.rank !== card_1.Rank.ACE) {
        throw new Error('Only the Ace can be placed at the bottom of a foundation');
    }
    if (targetFoundationPile.cards.length &&
        !(0, card_1.isValidFoundationSequence)((0, util_1.lastItem)(targetFoundationPile.cards), cardToPlace)) {
        const foundationTopCard = (0, util_1.lastItem)(targetFoundationPile.cards);
        throw new Error(`The provided card ${cardToPlace.rank} cannot be placed on top of ${foundationTopCard.rank}, expected a ` +
            `${card_1.RANK_SEQUENCE[card_1.RANK_SEQUENCE.indexOf(foundationTopCard.rank) + 1]} card.`);
    }
    const newFoundationPile = (0, pile_1.placeCardOnTop)(targetFoundationPile, cardToPlace);
    return Object.assign(Object.assign({}, foundation), { [cardToPlace.color]: newFoundationPile });
}
