"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shuffle = exports.slicePile = exports.turnCard = exports.placePileOnTop = exports.placeCardOnTop = exports.draw = exports.Pile = void 0;
const card_1 = require("./card");
class Pile {
    constructor(cards) {
        this.cards = cards;
    }
}
exports.Pile = Pile;
function draw(pile, numberOfCards) {
    if (!Number.isSafeInteger(numberOfCards) || numberOfCards <= 0) {
        throw new TypeError(`The numberOfCards parameter must be a positive safe integer, ${numberOfCards} was provided`);
    }
    const drawnCards = pile.cards.slice(-numberOfCards).reverse();
    const pileRemainder = new Pile(pile.cards.slice(0, Math.max(pile.cards.length - numberOfCards, 0)));
    return [pileRemainder, drawnCards];
}
exports.draw = draw;
function placeCardOnTop(pile, card) {
    return new Pile(pile.cards.concat(card));
}
exports.placeCardOnTop = placeCardOnTop;
function placePileOnTop(bottomPile, topPile) {
    return new Pile(bottomPile.cards.concat(topPile.cards));
}
exports.placePileOnTop = placePileOnTop;
function turnCard(pile, cardToTurn) {
    const index = pile.cards.indexOf(cardToTurn);
    if (index === -1) {
        throw new Error('The specified card is not present in the specified pile');
    }
    const patchedCards = pile.cards.slice();
    const newCard = (0, card_1.turnOver)(cardToTurn);
    patchedCards.splice(index, 1, newCard);
    return new Pile(patchedCards);
}
exports.turnCard = turnCard;
function slicePile(pile, separatingCard) {
    const separatorIndex = pile.cards.indexOf(separatingCard);
    if (separatorIndex === -1) {
        throw new Error('The specified card is not present in the specified pile');
    }
    const topPile = new Pile(pile.cards.slice(0, separatorIndex));
    const bottomPile = new Pile(pile.cards.slice(separatorIndex));
    return [topPile, bottomPile];
}
exports.slicePile = slicePile;
function shuffle(pile) {
    // https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm
    const cards = pile.cards.slice();
    for (let currentCardIndex = 0; currentCardIndex < cards.length - 1; currentCardIndex++) {
        const randomCardIndex = Math.floor(Math.random() * (cards.length - currentCardIndex)) + currentCardIndex;
        const currentCard = cards[currentCardIndex];
        cards[currentCardIndex] = cards[randomCardIndex];
        cards[randomCardIndex] = currentCard;
    }
    return new Pile(cards);
}
exports.shuffle = shuffle;
