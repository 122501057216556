"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.movePilePart = exports.revealTopCard = exports.removeTopCardFromPile = exports.addCardToPile = exports.Tableau = void 0;
const card_1 = require("./card");
const pile_1 = require("./pile");
const util_1 = require("./util");
class Tableau {
    constructor(piles) {
        this.piles = piles;
    }
}
exports.Tableau = Tableau;
function addCardToPile(tableau, pile, card) {
    const pileIndex = tableau.piles.indexOf(pile);
    if (pileIndex === -1) {
        throw new Error('The specified pile is not present in the specified tableau');
    }
    const patchedPile = (0, pile_1.placeCardOnTop)(pile, card);
    const patchedPiles = tableau.piles.slice();
    patchedPiles.splice(pileIndex, 1, patchedPile);
    return new Tableau(patchedPiles);
}
exports.addCardToPile = addCardToPile;
function removeTopCardFromPile(tableau, pile) {
    const pileIndex = tableau.piles.indexOf(pile);
    if (pileIndex === -1) {
        throw new Error('The specified pile is not present in the specified tableau');
    }
    if (!pile.cards.length) {
        throw new Error('The specified pile contains no cards');
    }
    const [pileRemainder, [topCard]] = (0, pile_1.draw)(pile, 1);
    const patchedPiles = tableau.piles.slice();
    patchedPiles.splice(pileIndex, 1, pileRemainder);
    return [new Tableau(patchedPiles), topCard];
}
exports.removeTopCardFromPile = removeTopCardFromPile;
function revealTopCard(tableau, pile) {
    if (!pile.cards.length) {
        throw new Error('The specified pile is empty');
    }
    if ((0, util_1.lastItem)(pile.cards).side === card_1.Side.FACE) {
        throw new Error('The top card is already face-up');
    }
    const pileIndex = tableau.piles.indexOf(pile);
    if (pileIndex === -1) {
        throw new Error('The specified pile is not present in the specified tableau');
    }
    const patchedPile = (0, pile_1.turnCard)(pile, (0, util_1.lastItem)(pile.cards));
    const patchedPiles = tableau.piles.slice();
    patchedPiles.splice(pileIndex, 1, patchedPile);
    return new Tableau(patchedPiles);
}
exports.revealTopCard = revealTopCard;
function movePilePart(tableau, sourcePile, topCardToMove, targetPile) {
    const sourcePileIndex = tableau.piles.indexOf(sourcePile);
    const targetPileIndex = tableau.piles.indexOf(targetPile);
    if (sourcePileIndex === -1) {
        throw new Error('The specified source pile is not present in the specified tableau');
    }
    if (targetPileIndex === -1) {
        throw new Error('The specified target pile is not present in the specified tableau');
    }
    const [sourcePileRemainder, movedPart] = (0, pile_1.slicePile)(sourcePile, topCardToMove);
    const patchedPiles = tableau.piles.slice();
    patchedPiles.splice(sourcePileIndex, 1, sourcePileRemainder);
    patchedPiles.splice(targetPileIndex, 1, (0, pile_1.placePileOnTop)(targetPile, movedPart));
    return new Tableau(patchedPiles);
}
exports.movePilePart = movePilePart;
