"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultStateRankingHeuristic = exports.makeMoveOnDesk = exports.makeMove = void 0;
const card_1 = require("./card");
const desk_1 = require("./desk");
const game_1 = require("./game");
const move_1 = require("./move");
const moveHintGenerator_1 = require("./moveHintGenerator");
const util_1 = require("./util");
function makeMove(game, options) {
    validateOptions(options);
    const { state: desk, rules } = game;
    if ((0, desk_1.isVictory)(desk)) {
        return game;
    }
    const bestMove = findBestMove(desk, rules, options, options.lookAheadMoves);
    if (!bestMove) {
        return game;
    }
    const [hint] = bestMove;
    return executeMoveHint(game, hint);
}
exports.makeMove = makeMove;
function makeMoveOnDesk(desk, rules, options) {
    validateOptions(options);
    if ((0, desk_1.isVictory)(desk)) {
        return desk;
    }
    const bestMove = findBestMove(desk, rules, options, options.lookAheadMoves);
    if (!bestMove) {
        return desk;
    }
    const [hint] = bestMove;
    return executeMoveHintOnDesk(desk, rules, hint, false);
}
exports.makeMoveOnDesk = makeMoveOnDesk;
function defaultStateRankingHeuristic({ foundation, tableau: { piles: tableauPiles } }) {
    // Total number of face-up cards on the desk, excluding stock/waste
    return Object.values(foundation).map((pile) => pile.cards.length).concat(tableauPiles.map((pile) => pile.cards.filter((card) => card.side === card_1.Side.FACE).length)).reduce((accumulator, value) => accumulator + value, 0);
}
exports.defaultStateRankingHeuristic = defaultStateRankingHeuristic;
function validateOptions(options) {
    if (!Number.isInteger(options.maxConsideredConfidenceLevels) || options.maxConsideredConfidenceLevels < 0) {
        throw new TypeError('The maxConsideredConfidenceLevels option must be a non-negative integer, ' +
            `${options.maxConsideredConfidenceLevels} was provided`);
    }
    if (!Number.isSafeInteger(options.lookAheadMoves) || options.lookAheadMoves < 0) {
        throw new TypeError(`The lookAheadMoves option must be a non-negative integer, ${options.lookAheadMoves} was provided`);
    }
}
function findBestMove(desk, rules, options, lookAheadMoves) {
    const hints = (0, moveHintGenerator_1.getMoveHints)(desk, rules, moveHintGenerator_1.HintGeneratorMode.WITH_FULL_STOCK);
    if (!hints.length) {
        return null;
    }
    if (options.minAutoAcceptConfidence &&
        moveHintGenerator_1.MOVE_CONFIDENCES.indexOf(hints[0][2]) <= moveHintGenerator_1.MOVE_CONFIDENCES.indexOf(options.minAutoAcceptConfidence)) {
        const deskAfterMove = executeMoveHintOnDesk(desk, rules, hints[0], true);
        const rank = options.stateRankingHeuristic(deskAfterMove);
        return [hints[0], rank];
    }
    let bestHint = hints[0];
    const highestConfidenceIndex = moveHintGenerator_1.MOVE_CONFIDENCES.indexOf(bestHint[2]);
    let bestRank = Number.NEGATIVE_INFINITY;
    for (const hint of hints) {
        if (moveHintGenerator_1.MOVE_CONFIDENCES.indexOf(hint[2]) > highestConfidenceIndex + options.maxConsideredConfidenceLevels) {
            continue;
        }
        const deskAfterMove = executeMoveHintOnDesk(desk, rules, hint, true);
        let currentMoveRank;
        if (lookAheadMoves && !(0, desk_1.isVictoryGuaranteed)(deskAfterMove)) {
            const deepAnalysisOutcome = findBestMove(deskAfterMove, rules, options, lookAheadMoves - 1);
            if (!deepAnalysisOutcome) {
                continue;
            }
            [, currentMoveRank] = deepAnalysisOutcome;
        }
        else {
            currentMoveRank = options.stateRankingHeuristic(deskAfterMove);
        }
        if (currentMoveRank > bestRank) {
            bestRank = currentMoveRank;
            bestHint = hint;
        }
    }
    return [bestHint, bestRank];
}
function executeMoveHint(game, hint) {
    const moveType = hint[0].move;
    const topWasteCard = (0, util_1.lastItemOrNull)(game.state.waste.cards);
    const needsToDrawCards = (moveType === move_1.MoveType.WASTE_TO_FOUNDATION || moveType === move_1.MoveType.WASTE_TO_TABLEAU) &&
        ((topWasteCard === null || topWasteCard === void 0 ? void 0 : topWasteCard.rank) !== hint[1].rank || topWasteCard.color !== hint[1].color);
    const moveToExecute = needsToDrawCards ? createStockCyclingMove(game.state, game.rules) : hint[0];
    return (0, game_1.executeMove)(game, moveToExecute);
}
function executeMoveHintOnDesk(deskState, rules, hint, autoDrawCards) {
    const moveType = hint[0].move;
    const topWasteCard = (0, util_1.lastItemOrNull)(deskState.waste.cards);
    const needsToDrawCards = (moveType === move_1.MoveType.WASTE_TO_FOUNDATION || moveType === move_1.MoveType.WASTE_TO_TABLEAU) &&
        ((topWasteCard === null || topWasteCard === void 0 ? void 0 : topWasteCard.rank) !== hint[1].rank || topWasteCard.color !== hint[1].color);
    const moveToExecute = needsToDrawCards ? createStockCyclingMove(deskState, rules) : hint[0];
    const deskAfterExecutingMove = (0, desk_1.executeMove)(deskState, rules, moveToExecute);
    return autoDrawCards && needsToDrawCards ?
        executeMoveHintOnDesk(deskAfterExecutingMove, rules, hint, autoDrawCards)
        :
            deskAfterExecutingMove;
}
function createStockCyclingMove(desk, rules) {
    return desk.stock.cards.length ?
        {
            drawnCards: rules.drawnCards,
            move: move_1.MoveType.DRAW_CARDS,
        }
        :
            {
                move: move_1.MoveType.REDEAL,
            };
}
